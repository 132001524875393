import { useEffect, useState } from "react";
// import Popular from "./popular/page";
import { FaPlay } from "react-icons/fa";

function useMobilePlatform() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobilePlatforms = /android|iphone|ipod|windows phone|blackberry/i;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    setIsMobile(mobilePlatforms.test(userAgent) || isIOS);
  }, []);

  return isMobile;
}

export default function Home() {
  const isMobile = useMobilePlatform();

  const handleFullScreen = () => {
    const iframe = document.getElementById("game-iframe");
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  const handleClick = () => {
    window.location.href = "/game";
  };

  return (
    <div className="relative z-50 bg-[#FFFCCE]">
      {!isMobile ?
        <div className="2xl:flex lg:flex flex items-center justify-center 2xl:h-[85vh] lg:h-[75vh] h-[65vh] lg:mx-24 mx-8">
          <iframe
            id="game-iframe"
            src="https://monkey-mart.gamestores.fun/"
            width="100%"
            height="100%"
            frameBorder="0"
            title="Monkey Mart"
            style={{
              aspectRatio: "16 / 9",
              objectFit: "cover",
              borderRadius: "8px",
              maxWidth: "fit-content",
              border: "2px solid #05D369",
            }}
          ></iframe>
        </div>
        :
        <div className="flex flex-col items-center justify-center h-[35vh] lg:mx-24 mx-8 opacity-90 rounded-lg">
          <div className="relative w-full h-full flex flex-col items-center justify-center">
            <div className="absolute inset-0 bg-playnow opacity-50"></div>
            <button className="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center relative animate-zoom" onClick={handleClick}>
              <FaPlay className="text-black w-6 h-6" />
            </button>
            <a className="poppins-bold font-bold text-[20px] text-white mt-3 relative"
              href="/game"
              style={{ textShadow: "1px 1px 2px black, -1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black" }}
            >Play now</a>
          </div>

        </div>
      }
      <div className="flex items-center justify-center">
        <div className="container">
          {!isMobile && <div className="2xl:flex lg:flex md:hidden hidden justify-center mt-6 2xl:mb-[40px] lg:mb-[28px] mb-[28px]">
            <button
              onClick={handleFullScreen}
              className="btn-full-screen 2xl:py-6 2xl:px-16 lg:py-3 lg:px-8 md:py-3 md:px-8 px-4 py-2 rounded"
            >
              <span className="lilita-one-regular 2xl:text-4xl lg:text-3xl md:text-3xl text-white">
                Full Screen
              </span>
            </button>
          </div>}
          {/* <Popular /> */}
          <div className='2xl:mx-56 2xl:mt-14 2xl:pb-14 md:pb-14 md:mt-14 mt-10 lg:mx-20 md:mx-9 mx-6 pb-10'>
            <h1 className='2xl:text-[40px] md:text-[40px] text-[32px] lilita-one-regular text-[#009C4C] text-center'>Play Monkey Mart Now!!</h1>
            <div className='2xl:mt-6 md:mt-6'>
              <span className='text-base flex !text-justify md:text-lg 2xl:text-lg lg:text-lg poppins-regular text-[#383838]'>
                Monkey Mart is a captivating online management game that allows players to step into the shoes of a supermarket manager. With its engaging gameplay and charming graphics, it has quickly become a favorite among simulation enthusiasts. In this guide, we'll explore the game's features, provide essential tips, and offer strategies to help you excel in running your virtual supermarket.
              </span>
            </div>
          </div>
          <div className="text-[#383838] lg:px-20 md:px-9 px-6 2xl:pt-20 lg:pt-20 md:pt-14 content-des 2xl:px-56 2xl:pb-20 md:pb-14 pt-14 pb-14">
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Understanding Monkey Mart
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              In Monkey Mart, players are tasked with managing a bustling supermarket. The primary objectives include stocking shelves, attending to customers, and expanding the store to increase profits. The game offers a variety of products to sell, from fresh produce to unique items, keeping the gameplay dynamic and challenging.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Key Features of Monkey Mart
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              <ul style={{
                listStyleType: 'disc',
                marginLeft: '30px'
              }}>
                <li>
                  Engaging Graphics: The game boasts vibrant, cartoonish visuals that appeal to players of all ages.
                </li>
                <li>
                  User-Friendly Controls: Designed for accessibility, Monkey Mart offers intuitive controls suitable for both new and experienced gamers.
                </li>
                <li>
                  Progressive Difficulty: As players advance, the game introduces new challenges, ensuring a continuously engaging experience.
                </li>
                <li>
                  Free to Play: Monkey Mart is accessible without any cost, making it an attractive option for budget-conscious gamers.
                </li>
              </ul>
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Tips and Strategies for Success
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              <ul style={{
                listStyleType: 'disc',
                marginLeft: '30px'
              }}>
                <li>
                  Maintain Stock Levels: Ensure shelves are consistently stocked to prevent customer dissatisfaction and lost sales.
                </li>
                <li>
                  Invest in Upgrades: Use profits to enhance your store, unlocking new sections and products to attract a broader customer base.
                </li>
                <li>
                  Efficient Time Management: As the game progresses, multitasking becomes crucial. Prioritize tasks to keep operations running smoothly.
                </li>
                <li>
                  Customer Satisfaction: Happy customers are repeat customers. Attend to their needs promptly to build loyalty and increase sales.
                </li>
              </ul>
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Why Players Love Monkey Mart
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              The charm of Monkey Mart lies in its blend of simplicity and strategic depth. Players enjoy the satisfaction of growing a small store into a thriving supermarket. The game's colorful graphics and evolving challenges keep players engaged, making it a delightful experience for those seeking both relaxation and stimulation.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Getting Started with Monkey Mart
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              To begin your journey as a supermarket manager, simply search for "Monkey Mart" in your preferred web browser. The game is available on various online gaming platforms and can be played directly without the need for downloads.
            </div>

            <div className="control flex items-center 2xl:mt-10 lg:mt-10 mt-4 md:mt-10">
              <div className="2xl:pl-8 2xl:py-8 lg:pl-8 lg:py-8 px-8 py-8">
                <h2 className="2xl:text-2xl lg:text-2xl md:text-2xl text-2xl font-bold text-start lilita-one-regular flex !text-justify">
                  Control - How to play
                </h2>
                <ul
                  style={{
                    listStyleType: "disc",
                    marginLeft: "30px",
                  }}
                >
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4 poppins-regular">
                    Run around the market to grab fresh crops and stand next to aisles stack them up for the customers to pick up. You don't need to press any buttons - your character will do all the work as long as you're standing in the right place!
                  </li>
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start poppins-regular">
                    Move - WASD or Arrow keys
                  </li>
                </ul>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}
